import user from '../user';
import { useSelector } from 'react-redux';

/**
 * Returns the Authorization header as part of an Object.
 *
 * @export
 * @param {Object} [server={}]
 * @param {Object} [server.requestOptions]
 * @param {string|function} [server.requestOptions.auth]
 * @returns {Object} { Authorization }
 */

function getCookieValue(name) {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    // Verifica si la cookie comienza con el nombre especificado
    if (cookie.startsWith(`${name}=`)) {
      // Obtiene el valor de la cookie
      return cookie.substring(name.length + 1);
    }
  }

  return null;
}

export default function getAuthorizationHeader({ requestOptions } = {}) {
  const headers = {};

  // Check for OHIF.user since this can also be run on the server
  // const accessToken = user && user.getAccessToken && user.getAccessToken();
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const token = params.get('token');
  if (token) {
    document.cookie = `ime-session=${token}; path=/;`;
  }
  const accessToken = getCookieValue('ime-session');

  // Auth for a specific server
  if (requestOptions && requestOptions.auth) {
    if (typeof requestOptions.auth === 'function') {
      // Custom Auth Header
      headers.Authorization = requestOptions.auth(requestOptions);
    } else {
      // HTTP Basic Auth (user:password)
      headers.Authorization = `Basic ${btoa(requestOptions.auth)}`;
    }
  }
  // Auth for the user's default
  else if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  return headers;
}
